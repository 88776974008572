import { useRouter } from 'next/router'
import { getRelativeUri } from 'lib/utils'
import { useQueryResult } from '@/components/generic/query-result-handler'
import GET_PAGE_METADATA_OVERRIDES_QUERY from './useMetadataOverridesQuery'
import MetadataOverrides from './MetadataOverrides'

const useMetadataOverrides = (): MetadataOverrides => {
  const { asPath } = useRouter()

  const { data: metadataOverrides } = useQueryResult(
    GET_PAGE_METADATA_OVERRIDES_QUERY,
    {
      variables: { where: { url: getRelativeUri(asPath) } },
      dataKey: 'metadataOverrides',
    }
  )

  const override = metadataOverrides?.[0]

  return override
}

export default useMetadataOverrides
