import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { promoterPastEventsByIdRoute } from 'lib/routes'
import QueryResultHandler from '@/components/generic/query-result-handler'
import EventsArchive from '@/components/events/sub-sections/events-archive'
import GET_PROMOTER_EVENTS_ARCHIVE from './GetPromoterEventsArchiveQuery'

const PromoterEventsArchive = ({ id }) => {
  const { asPath } = useRouter()
  const { loading, error, data } = useQuery(GET_PROMOTER_EVENTS_ARCHIVE, {
    variables: { id },
  })

  return (
    <QueryResultHandler
      loading={loading}
      error={error}
      data={data}
      dataKey="promoter.events"
      markup={EventsArchive}
      linkProps={promoterPastEventsByIdRoute(`${asPath}/past-events`)}
    />
  )
}

PromoterEventsArchive.propTypes = {
  id: PropTypes.string.isRequired,
}

export default PromoterEventsArchive
