import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { SubSection } from '@resident-advisor/design-system'
import { ThemeProvider } from 'styled-components'
import arrayHasData from 'lib/arrayHasData'
import { light } from '@/themes'
import messages from '@/messages/events'
import HorizontalList from '@/components/generic/horizontal-list'
import QueryResultHandler from '@/components/generic/query-result-handler'
import NewsCard from '@/components/editorial/cards/news'
import GET_PROMOTER_NEWS from './GetPromoterNewsQuery'

const PromoterNews = ({ id }) => {
  const props = useQuery(GET_PROMOTER_NEWS, {
    variables: { id },
  })

  return (
    <QueryResultHandler
      {...props}
      dataKey="promoter"
      markup={PromoterNewsMarkup}
    />
  )
}

PromoterNews.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export const PromoterNewsMarkup = ({ data }) => {
  const { formatMessage } = useIntl()

  return (
    data &&
    arrayHasData(data.news, 3) && (
      <ThemeProvider theme={light}>
        <SubSection.Stacked title={formatMessage(messages.promoterNews)}>
          <HorizontalList
            Card={NewsCard}
            mCols={3}
            items={data.news.slice(0, 3)}
          />
        </SubSection.Stacked>
      </ThemeProvider>
    )
  )
}

PromoterNewsMarkup.propTypes = {
  data: PropTypes.shape({
    contentUrl: PropTypes.string.isRequired,
    news: PropTypes.array.isRequired,
  }).isRequired,
}

export default PromoterNews
