import { useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Button, variants } from '@resident-advisor/design-system'
import { AnimatePresence } from 'framer-motion'
import dynamicLoadWithSpinner from 'lib/dynamicLoadWithSpinner'
import { AGGREGATIONS } from '@/enums/listing'
import music from '@/messages/music'
import dict from '@/messages/dict'
import magazine from '@/messages/magazine'
import buttonTrackingIds from '@/tracking/button-tracking-ids'
import SideModal from '@/components/generic/side-modal'

const ListingFilterModalContent = dynamicLoadWithSpinner(() =>
  import('./ListingFilterModalContent')
)

const ListingFilter = ({ type, selected, values, removeFilter, formatUrl }) => {
  const intl = useIntl()
  const [show, setShow] = useState(false)
  const toggleShow = () => setShow(!show)

  const buttonLabel = selected
    ? values.find(
        (filterValue) =>
          filterValue.value?.toLowerCase() === selected?.toLowerCase()
      )?.name
    : intl.formatMessage(filterTitle[type])

  return (
    <>
      <Button
        variant={variants.button.secondaryGrey}
        onClick={toggleShow}
        data-button-tracking-id={`${
          buttonTrackingIds.openListingFilterModal
        }-${type.toLowerCase()}`}
      >
        {buttonLabel}
      </Button>
      <AnimatePresence>
        {show && (
          <SideModal toggle={toggleShow}>
            <ListingFilterModalContent
              formatUrl={formatUrl}
              removeFilter={removeFilter}
              selected={selected}
              type={type}
              values={values}
            />
          </SideModal>
        )}
      </AnimatePresence>
    </>
  )
}

const filterTitle = {
  YEAR: music.year,
  COUNTRY: dict.country,
  GENRE: music.genre,
  INDEX: magazine.category,
}

ListingFilter.propTypes = {
  type: PropTypes.oneOf(Object.values(AGGREGATIONS)),
  selected: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  removeFilter: PropTypes.func.isRequired,
  formatUrl: PropTypes.func.isRequired,
}

ListingFilter.displayName = 'ListingFilter'
export default ListingFilter
