import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import arrayHasData from 'lib/arrayHasData'
import useDateArchiveNavigation from '@/hooks/useDateArchiveNavigation'
import ArchiveNavigator from '@/components/shared/archive-navigator'
import Listing from './Listing'

const CreateListingArchivePagination =
  (
    showForAggregations,
    defaultFirstYear,
    defaultEndYear,
    dateArchiveNavigationOptions,
    archiveNavigatorProps
  ) =>
  (appliedAggregationTypes, filtersData) =>
    isEqual(appliedAggregationTypes.sort(), showForAggregations.sort()) ? (
      <ListingArchivePagination
        filtersData={filtersData}
        defaultFirstYear={defaultFirstYear}
        defaultEndYear={defaultEndYear}
        dateArchiveNavigationOptions={dateArchiveNavigationOptions}
        archiveNavigatorProps={archiveNavigatorProps}
      />
    ) : null

const ListingArchivePagination = ({
  filtersData,
  defaultFirstYear,
  defaultEndYear,
  dateArchiveNavigationOptions,
  archiveNavigatorProps,
}) => {
  const yearFilter =
    arrayHasData(filtersData) &&
    filtersData.find((f) => f.type === Listing.Aggregations.Year)

  const yearFilterYears = yearFilter?.values?.map((filter) =>
    parseInt(filter.value)
  )

  const minimumYear = yearFilter
    ? Math.min(...yearFilterYears)
    : defaultFirstYear

  const maximumYear = yearFilter ? Math.max(...yearFilterYears) : defaultEndYear

  const { previous, next } = useDateArchiveNavigation({
    startDate: new Date(minimumYear, 0),
    endDate: new Date(maximumYear, 0),
    ...dateArchiveNavigationOptions,
  })

  return minimumYear === maximumYear ? null : (
    <ArchiveNavigator
      previous={previous}
      next={next}
      {...archiveNavigatorProps}
    />
  )
}

ListingArchivePagination.propTypes = {
  filtersData: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  defaultFirstYear: PropTypes.number.isRequired,
  defaultEndYear: PropTypes.number.isRequired,
  dateArchiveNavigationOptions: PropTypes.object,
  archiveNavigatorProps: PropTypes.object,
}

export default CreateListingArchivePagination
