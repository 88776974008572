import PropTypes from 'prop-types'
import {
  Detail,
  Text,
  Grid,
  Column,
  Alignment,
  variants,
} from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import normalizeNumber from 'lib/normalizeNumber'
import dict from '@/messages/dict'
import FollowButton, {
  FOLLOW_BUTTON_TYPES,
} from '@/components/shared/follow-button'

const BUTTON_HEIGHT = 40

const FollowDetail = ({ followerCount, id, isFollowing, type }) => {
  const intl = useIntl()

  const hasFollowers = followerCount > 0

  return (
    <Grid lCols={3} mCols={2} sCols={3}>
      {hasFollowers && (
        <Column>
          <Detail title={intl.formatMessage(dict.followers)} />
          <Alignment alignItems="center" height={BUTTON_HEIGHT}>
            <Text variant={variants.text.heading.m} color="accent">
              {normalizeNumber(followerCount, intl)}
            </Text>
          </Alignment>
        </Column>
      )}
      <Column
        lSpan={hasFollowers ? 2 : 3}
        mSpan={hasFollowers ? 1 : 2}
        sSpan={2}
      >
        <Alignment
          justifyContent={{ s: 'flex-start', m: 'flex-end' }}
          alignItems="flex-end"
          height="100%"
        >
          <FollowButton
            id={id}
            isFollowing={isFollowing}
            type={type}
            followerCount={followerCount}
          />
        </Alignment>
      </Column>
    </Grid>
  )
}

FollowDetail.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isFollowing: PropTypes.bool,
  type: PropTypes.oneOf(Object.keys(FOLLOW_BUTTON_TYPES)).isRequired,
  followerCount: PropTypes.number.isRequired,
}

export default FollowDetail
