import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { StickyContainer } from 'react-sticky'
import { colors } from '@resident-advisor/design-system'
import useFilterRemove from '@/hooks/useFilterRemove'
import InfiniteScrollQueryResultHandler from '@/components/generic/infinite-scroll-query-result-handler'
import InfiniteScrollPaginationSEO from '@/components/generic/infinite-scroll-pagination-seo'
import NoResults from '@/components/generic/no-results'
import * as ListingEnums from '@/enums/listing'
import ListingFilter from './listing-filter'
import useListingQuery from './useListingQuery'

const Listing = ({
  children,
  createSubNav,
  type,
  createContent,
  createPagination,
  baseFilters,
  aggregations,
  formatUrl,
  handleRemoveFilter,
  sortOrder,
  sortField,
  defaultFilters,
  queries,
  backgroundColor,
}) => {
  const defaultHandleRemoveFilter = useFilterRemove({
    defaultToIgnore: ['slug', 'idOrCountry', 'username', 'page'],
  })
  const { query } = useRouter()

  const {
    queryResult,
    parsedQuery,
    filters,
    headPaginationAttributes,
    aggregationsData,
  } = useListingQuery({
    type,
    baseFilters,
    aggregations,
    sortOrder,
    sortField,
    defaultFilters,
    queries,
  })

  const createQueryParams = ({ value, type: urlType }) => {
    const { page, ...queryWithoutPage } = query
    return formatUrl({
      ...queryWithoutPage,
      [urlType.toLowerCase()]: value,
    })
  }

  const pagination = createPagination?.(
    filters.reduce((acc, filter) => [...acc, filter.type], []),
    queryResult?.loading ? [] : aggregationsData
  )

  return (
    <StickyContainer>
      {createSubNav(
        queryResult?.loading
          ? []
          : aggregationsData?.map((aggregation) => (
              <ListingFilter
                {...aggregation}
                formatUrl={createQueryParams}
                removeFilter={handleRemoveFilter ?? defaultHandleRemoveFilter}
                selected={
                  parsedQuery.find((item) => item.type === aggregation.type)
                    ?.value
                }
              />
            ))
      )}
      {children}
      <InfiniteScrollQueryResultHandler
        {...queryResult}
        dataKey="listing.data"
        markup={createContent}
        handleEmpty={NoResults}
        backgroundColor={backgroundColor ?? colors.grey[80]}
      />
      {pagination}
      {!pagination && (
        <InfiniteScrollPaginationSEO
          headPaginationAttributes={headPaginationAttributes}
          fetchingNextPage={queryResult?.fetchingNextPage}
          finishedPaginating={queryResult?.finishedPaginating}
          loading={queryResult?.loading}
        />
      )}
    </StickyContainer>
  )
}

Listing.propTypes = {
  backgroundColor: PropTypes.string,
  type: PropTypes.oneOf(Object.values(ListingEnums.TYPE)).isRequired,
  createSubNav: PropTypes.func.isRequired,
  createContent: PropTypes.func.isRequired,
  createPagination: PropTypes.func,
  sortField: PropTypes.oneOf(Object.values(ListingEnums.SORT_FIELD)).isRequired,
  sortOrder: PropTypes.oneOf(Object.values(ListingEnums.SORT_ORDER)),
  aggregations: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(ListingEnums.AGGREGATIONS))
  ),
  baseFilters: PropTypes.array,
  formatUrl: PropTypes.func,
  handleRemoveFilter: PropTypes.func,
  defaultFilters: PropTypes.array,
  children: PropTypes.node,
  queries: PropTypes.exact({
    default: PropTypes.object,
    modified: PropTypes.object,
  }),
}

Listing.defaultProps = {
  baseFilters: [],
  formatUrl: () => ({}),
}

Listing.Type = ListingEnums.TYPE
Listing.Aggregations = ListingEnums.AGGREGATIONS
Listing.SortOrder = ListingEnums.SORT_ORDER
Listing.SortField = ListingEnums.SORT_FIELD

export default Listing
