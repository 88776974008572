import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import {
  promoterByIdRoute,
  promoterEventsByIdRoute,
  promoterPastEventsByIdRoute,
  promoterNewsByIdRoute,
} from 'lib/routes'
import messages from '@/messages/events'
import trackingIds from '@/tracking/tracking-ids'
import SubNav from '@/components/generic/sub-nav'

const PromoterDetailSubNav = ({
  contentUrl,
  hasUpcomingEvents,
  subNavFilters,
}) => {
  const intl = useIntl()
  const subNavItems = createSubNavItems(intl, contentUrl, hasUpcomingEvents)

  return (
    <SubNav
      subNavFilters={subNavFilters}
      trackingId={trackingIds.promoterDetailSubNav}
      subNavItems={subNavItems}
    />
  )
}

const createSubNavItems = (intl, contentUrl, hasUpcomingEvents) => {
  const subNavItems = [
    {
      title: intl.formatMessage(messages.overview),
      ...promoterByIdRoute(contentUrl),
    },
  ]

  if (hasUpcomingEvents) {
    subNavItems.push({
      title: intl.formatMessage(messages.upcomingEventsSubnavTitle),
      ...promoterEventsByIdRoute(`${contentUrl}/events`),
    })
  }

  subNavItems.push({
    title: intl.formatMessage(messages.pastEventsSubnavTitle),
    ...promoterPastEventsByIdRoute(`${contentUrl}/past-events`),
  })

  subNavItems.push({
    title: intl.formatMessage(messages.raNews),
    ...promoterNewsByIdRoute(`${contentUrl}/news`),
  })

  return subNavItems
}

PromoterDetailSubNav.propTypes = {
  contentUrl: PropTypes.string.isRequired,
  hasUpcomingEvents: PropTypes.bool,
  subNavFilters: PropTypes.object,
}

export default PromoterDetailSubNav
