import PropTypes from 'prop-types'
import {
  Detail,
  Section,
  Layout,
  Text,
  Grid,
  Column,
  variants,
} from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import { getAreaSafeName, generateUrlForAreaAndCountry } from 'lib/utils'
import { guideByAreaRoute } from 'lib/routes'
import messages from '@/messages/events'
import dict from '@/messages/dict'
import trackingIds from '@/tracking/tracking-ids'
import Link from '@/components/generic/link'
import { FOLLOW_BUTTON_TYPES } from '@/components/shared/follow-button'
import FollowDetail from '@/components/shared/follow-detail'
import PromoterLinks from './PromoterLinks'

const PromoterDetailBar = ({
  id,
  name,
  area,
  followerCount,
  links,
  isFollowing,
}) => {
  const intl = useIntl()

  return (
    <Section py={4} data-tracking-id={trackingIds.promoterDetailBar}>
      <Layout>
        <Grid
          lCols={12}
          mCols={8}
          sCols={4}
          gridTemplateAreas={{ s: sGTA, m: mGTA, l: lGTA }}
        >
          {area.name && (
            <Column area={Areas.Region}>
              <Detail title={intl.formatMessage(messages.region)}>
                <Text color="primary" variant={variants.text.small}>
                  <Link
                    variant={variants.text.small}
                    underline
                    {...guideByAreaRoute(
                      generateUrlForAreaAndCountry('/guide', area)
                    )}
                  >
                    {getAreaSafeName(area, area.country)}
                  </Link>
                </Text>
              </Detail>
            </Column>
          )}
          {links && links.length > 0 && (
            <Column area={Areas.Links}>
              <Detail title={intl.formatMessage(dict.links)} />
              <PromoterLinks links={links} id={id} name={name} />
            </Column>
          )}
          <Column area={Areas.Followers}>
            <FollowDetail
              id={id}
              isFollowing={isFollowing}
              type={FOLLOW_BUTTON_TYPES.promoter}
              followerCount={followerCount}
            />
          </Column>
        </Grid>
      </Layout>
    </Section>
  )
}

const lGTA = `
  "region region links links links . . . . followers followers followers"
`
const mGTA = `
  "region region links links links . followers followers"
`
const sGTA = `
  "region region links links"
  "followers followers followers . "
`

const Areas = {
  Region: 'region',
  Links: 'links',
  Followers: 'followers',
}

PromoterDetailBar.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string,
  area: PropTypes.object,
  followerCount: PropTypes.number,
  links: PropTypes.array,
  isFollowing: PropTypes.bool,
}

export default PromoterDetailBar
